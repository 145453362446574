<!--  -->
<template>
  <div class="workDetail">
    <div class="detail-box">
      <div class="tab-title-name" v-if="id">
        <p>岗位列表 > 岗位详情</p>
        <p></p>
      </div>
      <el-card class="essential-information-card" shadow="hover">
        <div class="essential-information-card-top">
          <span>{{ listData.postName }}</span>
          <span>{{ listData.postSalary }}</span>
        </div>
        <div class="essential-information-card-txt">
          <div>
            <p>学历要求：{{ listData.educationalRequirements }}</p>
            <p>联系人：{{ listData.contacts }}</p>
            <p>公司名称：{{ listData.corporateName }}</p>
          </div>
          <div>
            <p>工作年限：{{ listData.workingYears }}</p>
            <p>联系电话：{{ listData.telephone }}</p>
            <p>公司地址：{{ listData.companyAddress }}</p>
          </div>
        </div>
        <div style="margin-top: 10px">
          <p>公司简介：</p>
          <p style="margin-left: 80px; margin-top: -22px">
            {{ listData.companyResume }}
          </p>
        </div>
      </el-card>
      <el-card class="job-description-card" shadow="hover">
        <div class="job-description-card-title">岗位描述</div>
        <div class="job-description-card-txt">
          <p v-for="(ser,index) in newListDataJobDescription" :key="index"> {{ser}} </p>
        </div>
      </el-card>
      <div class="detail-button">
        <el-button type="info" @click="outHand">返回</el-button>
        <el-button type="primary" @click="dialogVisible = true"
          >联系对方</el-button
        >
      </div>
    </div>

    <el-dialog title="应聘此岗位" :visible.sync="dialogVisible" width="600px">
      <div class="dialog">
        <div>联系人：{{ listData.contacts }}</div>
        <div>{{ listData.telephone }}</div>
        <div>请拨打此手机号联系</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >我知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      dialogVisible: false,
      listData: [],
      newListDataJobDescription: [],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.id = this.$route.query.id;
    this.listHand();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    outHand() {
      this.$router.go(-1);
    },
    listHand() {
      const newData = JSON.parse(localStorage.getItem("talentData"));
      const okList = newData.filter((item) => item.id == this.id);
      this.listData = okList[0];
      var reg = new RegExp("[；。：:;！!]"); //创建正则表达式对象
      this.newListDataJobDescription = okList[0].jobDescription.split(reg);
    },
  },
  destroyed() {
    onpopstate = () => {
      //后退按钮触发事件
      let type = sessionStorage.getItem("type");
      sessionStorage.setItem("type", type);
    };
    // 销毁子组件，销毁观察者，事件监听者
    // 元素的事件还在，但是更改数据不会再让视图进行更新了；
  },
};
</script>
<style lang='scss' scoped>
/* @import url(); 引入css类 */
.workDetail {
  max-width: 1920px;
  min-height: 941px;
  overflow: hidden;
  background: #f5f7fa;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  .detail-box {
    max-width: 1184px;
    min-height: 914px;
    .essential-information-card {
      min-width: 1184px;
      min-height: 250px;
      background: #ffffff;
      border: 1px solid #ffffff;
    }
    .job-description-card {
      margin-top: 10px;
      min-width: 1184px;
      max-height: 654px;
      background: #ffffff;
      border: 1px solid #ffffff;
      .job-description-card-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #302e37;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .job-description-card-txt {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #302E37;
        line-height: 22px;
        >p{
          margin-top: 10px;
        }
      }
    }
  }
}
.tab-title-name {
  margin-top: 26px;
  > :nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.7);
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  > :nth-child(2) {
    width: 1184px;
    height: 1px;
    background: rgba(48, 46, 55, 0.1);
    margin: 8px 0px 16px 0px;
  }
}
.essential-information-card-top {
  display: flex;
  justify-content: space-between;
  :nth-child(1) {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #302e37;
    line-height: 42px;
  }
  :nth-child(2) {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff7d3e;
    line-height: 50px;
  }
}
.essential-information-card-txt {
  display: flex;
  > :nth-child(1) {
    min-width: 300px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #302e37;
    line-height: 40px;
  }
  > :nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #302e37;
    margin-left: 150px;
    line-height: 40px;
  }
}
.detail-button {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  :nth-child(1) {
    width: 268px;
    height: 40px;
    border: none;
  }
  :nth-child(2) {
    width: 268px;
    height: 40px;
    background: #ff7d3e;
    border: none;
  }
}
.dialog {
  text-align: center;
  :nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.7);
    line-height: 22px;
  }
  :nth-child(2) {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff7d3e;
    line-height: 50px;
  }
  :nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #302e37;
    line-height: 22px;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
  .el-button {
    width: 268px;
    height: 40px;
    background: #ff7d3e;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border: none;
  }
}
</style>